import Flow from "../Flow";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useCallback, useEffect } from "react";
import { useHash } from "../Flow/hooks";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { InstructionsContent } from "./InstructionsContent";

Modal.setAppElement("#root");

function App() {
  const [hash, setHash] = useHash();

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      localStorage.setItem("hasVisited", "true");
      setHash("#instructions");
    }
  }, [setHash]);

  const closeModal = useCallback(() => {
    setHash("");
  }, [setHash]);

  return (
    <div className="App">
      <header className="App-header">
        <span>
          <img src="logo-text.svg" height="40px" />
        </span>
        |
        <span>
          <a
            href="https://forms.gle/5z9WnJL691d5oQ73A"
            style={{ textDecorationLine: "none" }}
          >
            Waitlist
          </a>
        </span>
        |
        <span>
          <a
            href="https://discord.gg/qMrcprgejB"
            style={{ textDecorationLine: "none" }}
          >
            Discord
          </a>
        </span>
      </header>
      <Flow />
      <ToastContainer />
      <Modal
        isOpen={hash === "#instructions"}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        onRequestClose={closeModal}
      >
        <div style={{ float: "right", cursor: "pointer" }} onClick={closeModal}>
          <AiOutlineClose color="red" />
        </div>
        <InstructionsContent onClose={closeModal} />
      </Modal>
    </div>
  );
}

export default App;
