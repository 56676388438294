interface InstructionsContentProps {
  onClose: () => void;
}
export const InstructionsContent = (props: InstructionsContentProps) => {
  return (
    <div>
      <h1>
        <img src="logo.svg" height="40px" /> Jotte Instructions
      </h1>
      <p>
        Welcome to Jotte, an AI-powered graph-based writing tool that helps you
        create high-quality, informative content with ease. Jotte uses nodes and
        varying specificities of summaries to carry relevant information through
        an extremely long set of text, making it an ideal tool for creating
        long-form content.
      </p>
      <h2>Step 0: Controls & Core Loop</h2>
      <h3>Controls</h3>
      <div className="instruction-entry">
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Key</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Select</td>
              <td>Left Click</td>
            </tr>

            <tr>
              <td>Pan</td>
              <td>Left Click + Drag</td>
            </tr>
            <tr>
              <td>Delete</td>
              <td>Backspace</td>
            </tr>
            <tr>
              <td>Zoom</td>
              <td>Mouse Wheel</td>
            </tr>
            <tr>
              <td>Create Edge</td>
              <td style={{ paddingRight: "2rem" }}>
                Left Click + Drag on handle
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>Core loop</h3>
      <div className="instruction-entry">
        <ol>
          <li>
            <b>Set up the story</b>: Use top node, and create outlines.
          </li>
          <li>
            <b>Deepen the story</b>: Use the outline nodes to create more
            outline nodes until the desired depth is reached.
          </li>
          <li>
            <b>Write the story</b>: Use the outline nodes to create text nodes
            that the readers will read.
          </li>
          <li>
            <b>Summarize the story</b>: Use the outline nodes to create
            summaries of the bottom text nodes.
          </li>
          <li>
            <b>Repeat</b>: Repeat steps 2-4 until the desired story is reached.
          </li>
        </ol>
      </div>

      <h2>Step 1. Node Types</h2>
      <h3>Anatomy of a node</h3>
      <div className="instruction-entry">
        <img
          src="instructions/anatomy-of-node.png"
          style={{ maxWidth: "80vw" }}
        />
        {/* <p>There's 3 types of nodes. Top, </p> */}
      </div>

      <h3>Types of nodes</h3>
      <div className="instruction-entry">
        <img
          src="instructions/node-types.png"
          alt="Different node types"
          style={{ maxWidth: "50vw" }}
        />
        <div>
          <p>There's 3 types of nodes. Top, outline, and text.</p>
          <p>
            <b>Top</b>: There is only ever one top node, and this is where you
            set the whole story up.
          </p>
          <p>
            <b>Outline</b>: Outline nodes are used to create a structure for
            your story. They are used to create a hierarchy of information, and
            are used to create the outline of your story.
          </p>
          <p>
            <b>Text</b>: Text nodes are used to create the actual content of
            your story, that the readers will read.
          </p>
        </div>
      </div>

      <h2>Step 2: How to write in a graph-based world</h2>
      <div className="instruction-entry">
        <img src="instructions/dfs.gif" style={{ maxWidth: "50vw" }} />
        <div>
          <p>
            We recommend that you write in a depth-first-search order (like this
            gif).
          </p>
          <p>
            Each time you get to the end and finish off a set of text nodes, you
            should summarize the parent.
          </p>
        </div>
      </div>
      <div className="instruction-entry">
        <img
          src="instructions/factors-influencing-nodes.png"
          style={{ maxWidth: "50vw" }}
        />
        <div>
          <p>
            In this picture you can see the 4 direct factors that influence the
            generation of new information.
          </p>
          <ol>
            <li>
              <b>Parent Theme</b>: From the direct parent
            </li>
            <li>
              <b>Parent Event</b>: From the direct parent
            </li>
            <li>
              <b>Global Theme</b>: From the root node
            </li>
            <li>
              <b>Siblings' summary(ies)</b>: All previous siblings (from left to
              right) before this node.
            </li>
          </ol>
          <p>
            The siblings' summaries are created after their respective children
            are finished and summarized.
          </p>
        </div>
      </div>
      <hr />

      <h2>Thanks for reading!</h2>
      <p>
        If you like what we've done, or interested in improvements, please fill
        out our waitlist. It helps a lot!{" "}
      </p>
      <div>
        <a
          className="button-panel"
          style={{ textDecorationLine: "none", color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://forms.gle/nPXRixJ8SmEb7WAN6"
        >
          🎉 Join our Waitlist
        </a>{" "}
        <button className="button-panel" onClick={props.onClose}>
          Close Instructions
        </button>
      </div>
    </div>
  );
};
