import { useCallback, useEffect, useState } from "react";
import { useStore } from "./store";

export const useSummarize = (id: string) => {
  const getSummary = useStore((s) => s.getSummary);
  const [loading, setLoading] = useState(false);

  const summarize = useCallback(async () => {
    setLoading(true);
    await getSummary(id);
    setLoading(false);
  }, [id, getSummary]);

  return { summarize, loading };
};

export const useHash = (): [string, (newHash: string) => void] => {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash];
};
