import ReactFlow, { MiniMap, Controls, Background, Panel } from "reactflow";
import { shallow } from "zustand/shallow";
import { RFState, useStore } from "./store";
import { MidNode } from "./Nodes/MidNode";

// this is important! You need to import the styles from the lib to make it work
import "reactflow/dist/style.css";

import "./Flow.css";
import { InteractionPanel } from "./InteractionPanel";
import { TopNode } from "./Nodes/TopNode";
import { BottomNode } from "./Nodes/BottomNode";
import { BrowserView, MobileView } from "react-device-detect";

const nodeTypes = {
  // root:
  top: TopNode,
  mid: MidNode,
  bottom: BottomNode,
};

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
});

function Flow() {
  const { nodes, edges, onNodesChange, onEdgesChange, onConnect } = useStore(
    selector,
    shallow
  );

  return (
    <div className="Flow">
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        nodeTypes={nodeTypes}
        deleteKeyCode={["Backspace", "Delete"]}
        // panOnScroll
        // selectionOnDrag
      >
        <Panel position="top-right">
          <InteractionPanel />
        </Panel>
        {/* <Panel position="bottom-center">
          <button
            className="button-waitlist"
            style={{
              // minWidth: "20vw",
              maxWidth: "30vw",
              // minHeight: "10vh",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginBottom: "1vh",
            }}
          >
            <a
              style={{
                fontSize: "1.5rem",
                textDecoration: "none",
                display: "block",
              }}
              target="_blank"
              rel="noreferrer"
              href="https://forms.gle/nPXRixJ8SmEb7WAN6"
            >
              Join our Waitlist 🎉
            </a>
          </button>
        </Panel> */}
        <BrowserView>
          <MiniMap position="bottom-right" zoomable pannable />
        </BrowserView>
        {/* <MobileView>
          <MiniMap position="top-left" zoomable pannable />
        </MobileView> */}
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}

export default Flow;
