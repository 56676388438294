//  ========================
//  TYPEDEFS STOLEN BY COPYING FROM BACKEND
//  ========================

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://jotte-prod.kevin51jiang.workers.dev/api"
    : // : "https://jotte-dev.kevin51jiang.workers.dev/api";
      "http://localhost:8787/api";

export interface BruhMoment {
  id: string;
  text: string;
}

export interface CustomGenerateResponse {
  generated: BruhMoment[];
}

export interface GenerateStoryOutlineArgs {
  theme: string;
  events: string;
}

export interface GenerateStoryMiddleArgs {
  globalTheme: string; // Theme of the whole story
  // Don't need global events, because we can just use the summary, and it makes AI anticipate things too much

  parentTheme: string; // Theme of the parent chapter
  parentEvents: string; // Events that will happen in the parent chapter
  chapterPreviousSummary: string; // Summary of previous events that happened in this chapter

  chapterEvents: string; // Events that will happen in this chapter
  chapterTheme: string; // Theme of this chapter
}

export interface GenerateStoryBottomArgs {
  globalTheme: string; // Theme of the whole story
  // Don't need global events, because we can just use the summary, and it makes AI anticipate things too much

  // parentTheme: string; // Theme of the parent chapter
  parentEvents: string; // Events that will happen in the parent chapter
  chapterPreviousSummary: string; // Summary of previous events that happened in this chapter

  chapterEvents: string; // Events that will happen in this chapter
  chapterTheme: string; // Theme of this chapter
}

export interface GenerateSummaryArgs {
  text: string;
}

export const generateStoryOutline = async (
  args: GenerateStoryOutlineArgs
): Promise<CustomGenerateResponse> => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password: "roodab", ...args }),
  };

  return fetch(`${API_URL}/gen/outline`, options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const generateStoryMiddle = async (
  args: GenerateStoryMiddleArgs
): Promise<CustomGenerateResponse> => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password: "roodab", ...args }),
  };

  return fetch(`${API_URL}/gen/middle`, options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const generateStoryBottom = async (
  args: GenerateStoryBottomArgs
): Promise<{ generated: string[] }> => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password: "roodab", ...args }),
  };

  return fetch(`${API_URL}/gen/bottom`, options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export const generateSummary = async (
  args: GenerateSummaryArgs
): Promise<CustomGenerateResponse> => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ password: "roodab", ...args }),
  };

  return fetch(`${API_URL}/gen/summary`, options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};
